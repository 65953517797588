import React from 'react';
import { useSelector } from 'react-redux';
import SimpleImage from '../../SimpleImage';
import SimpleText from '../../SimpleText';
import './style.css';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux'
import { openModal } from '../../../actions/modal.action';
import qualtricsDocumentation from './qualtricsDocumentation';

const Informative = () => {
  const informative = useSelector((state) => state.wizard.informative);
  const {
    title, imageSrc, imageAlt, description, action, actionLabel } = informative;
  const dispatch = useDispatch();
  return (
    <div className="Informative">
      <div className="Informative-content">
        <h3 className="Informative-title">{title}</h3>
        <SimpleImage
          src={imageSrc}
          alt={imageAlt}
          title={imageAlt}
          align="center"
          margin="0 0 35px 0"
        />
        <SimpleText
          max={256}
          padding="0 45px"
          text={[description]}
          align="center"
        />
        {action && (
          <Button variant="link" onClick={() => action(openModal, dispatch, qualtricsDocumentation())}>{actionLabel}</Button>
        )}
      </div>
    </div>
  );
};

export default Informative;
