import types from '../types';

const initialState = {
  awsFolder: false,
  randS0ImportToBentoStatus: false,
  replaceDataSetS0ImportToBentoStatus: false,
  visualizationS1BentoStatus: false,
  qualtricsImportToBentoStatus: false,
};

const validationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reducerTypes.SET_AWS_FOLDER: {
      const awsFolder = action.payload.folder;
      return { ...state, awsFolder };
    }

    case types.reducerTypes.RAND_S0_IMPORT_TO_BENTO_STATUS: {
      return { ...state, randS0ImportToBentoStatus: action.payload };
    }

    case types.reducerTypes.REPLACEDATASET_S0_IMPORT_TO_BENTO_STATUS: {
      return { ...state, replaceDataSetS0ImportToBentoStatus: action.payload };
    }

    case types.reducerTypes.VISUALIZATION_S1_BENTO_STATUS: {
      return { ...state, visualizationS1BentoStatus: action.payload };
    }

    case types.reducerTypes.RESET_VALIDATIONS: {
      return { ...state, ...initialState };
    }

    case types.reducerTypes.QUALTRICS_IMPORT_TO_BENTO_STATUS: {
      return { ...state, qualtricsImportToBentoStatus: action.payload };
    }

    default:
      return { ...state };
  }
};
export default validationsReducer;
