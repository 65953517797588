import React from "react";
import './style.css';

const qualtricsDocumentation = () => {
    return <div className="text-justify">
        <b>Download the Response data file</b>
        <ol>
            <li>In your Qualtrics survey project, select the Data & Analysis tab</li>
            <li>Click Export & Import {'>'} Export Data… <br /> <img alt="doc 1" src="/providers/docs/doc-1.png" className="w-100" /></li>
            <li>Download the response data from Qualtrics as a CSV according to these configurations (note that you may need to select “More Options” to see the full configuration options): <br/> <img alt="doc 2" src="/providers/docs/doc-2.png" className="w-75" /> </li>
            <li>Save a copy of this file </li>
        </ol>
        <b>Download the Survey instrument file</b>
        <ol>
            <li>In your Qualtrics survey project, select the Survey tab</li>
            <li>Select Tools {'>'} Import/Export {'>'} Export survey <br/> <img alt="doc 3" src="/providers/docs/doc-3.png" className="w-100" /></li>
            <li>The file should automatically download to your computer as a QSF file</li>
        </ol>
        <b>You can now upload your files to Bento</b>
    </div>
}

export default qualtricsDocumentation;