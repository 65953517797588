/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faChevronLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  QUESTION_VALUE_TYPE,
  QUESTION_FILTER_TYPE,
  AUTO_SAVING_TIME,
} from '../../../../constants/common';
import themes, { SurveyThemeNulled } from '../../../../constants/themes';
import FilterCategory from './filter.category';
import types from '../../../../types';
import {
  updateSurveySampleById,
  getSurveySampleById,
  autoSavedSurveySample,
  autoSavingSurveySample,
  setAutoSavedPending,
} from '../../../../actions/survey.sample.action';
import {
  getSurveyLocationFilter,
} from '../../../../actions/survey.details.action';
import './styles.css';
import { postSurveyVisualizations } from '../../../../actions/survey.details.action';
import SampleDimension404Dialog from './sample.dimension.404.dialog';

const SurveySTypeQuestionEditForm = ({ handleEditMode }) => {
  const dispatch = useDispatch();
  const storeJwt = useSelector((state) => state.session.jwt);
  const [selectedTheme, setSelectedTheme] = useState();
  const [prompt, setPrompt] = useState('');
  const [labels, setLabels] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [filterType, setFilterType] = useState('');
  const {
    sample,
    dimensions,
    sampleUpdated,
    autoSaving,
    autoSavingPending,
    autoSaved,
    error: responseError,
  } = useSelector((state) => state.surveySample);
  const { visualizations } = useSelector((state) => state.surveyDetails);

  const onQuestionPromtChange = (e) => {
    dispatch(setAutoSavedPending());
    setPrompt(e.target.value);
  };

  const onAnswerChange = (e, index) => {
    dispatch(setAutoSavedPending());
    const answersToUpdate = [...answers];
    answersToUpdate[index].value = e.target.value;
    setAnswers([...answersToUpdate]);
  };

  const onLabelChange = (e, index) => {
    dispatch(setAutoSavedPending());
    const labelsToUpdate = [...labels];
    labelsToUpdate[index].value = e.target.value;
    setAnswers([...labelsToUpdate]);
  };

  useEffect(() => {
    if (!sample || !dimensions) return;
    const text = [QUESTION_VALUE_TYPE.MULTIPLE, QUESTION_VALUE_TYPE.GRID].includes(
      sample?.Data.valueType
    )
      ? sample.Data.text
      : sample.Data.label;

    setPrompt(text);
    setFilterType(sample.Data.filterType);

    if (sample?.Data.valueType !== QUESTION_VALUE_TYPE.MULTIPLE) {
      const { allowedValues } = dimensions[0].Data;
      setAnswers(
        Object.keys(allowedValues || [])
          .filter((key) => key >= 0)
          .map((key) => ({ id: key, value: allowedValues[key] }))
      );
    }
    if ([QUESTION_VALUE_TYPE.MULTIPLE, QUESTION_VALUE_TYPE.GRID].includes(sample?.Data.valueType)) {
      const labelList = dimensions.map((dimension) => ({
        id: dimension.Id,
        value: dimension.Data.label,
      }));
      setLabels(labelList);
    }
  }, [sample, dimensions]);

  const updateDimensions = useCallback(() => {
    if (!dimensions) return;
    answers.forEach((an) => {
      dimensions[0].Data.allowedValues[an.id] = an.value;
    });
    if (labels.length === dimensions.length) {
      labels.forEach((label, index) => {
        dimensions[index].Data.label = label.value;
        dimensions[index].Data.text = prompt;
      });
    }
  }, [answers, dimensions, labels, prompt]);

  const updateSampleAndDimensions = (theme = null, _filterType = null) => {
    updateDimensions();
    sample.Data.label = prompt;
    sample.Data.text = prompt;
    sample.Data.filterType = _filterType || filterType;
    dispatch({
      type: types.reducerTypes.SURVEY_SAMPLE_DIMENSION_GET_SUCCESS,
      payload: {
        sample,
        dimensions,
        sampleTheme: theme,
      },
    });
  };

  const updateVisualizationTheme = (theme) => {
    visualizations.details.scheme.index = theme.index;
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_SUCCESS,
      payload: visualizations,
    });
  };

  const onSave = useCallback(() => {
    if (!sample) return;
    const { Id } = sample;
    updateDimensions();
    dispatch(
      updateSurveySampleById(
        Id,
        {
          Id,
          Data: {
            label: prompt,
            text: prompt,
            filterType,
          },
        },
        dimensions[0],
        storeJwt
      )
    );
    dispatch(postSurveyVisualizations(visualizations.contextId, visualizations, storeJwt));
    dispatch(autoSavedSurveySample());
    dispatch(getSurveyLocationFilter(visualizations.contextId, storeJwt));
  }, [
    dispatch,
    visualizations,
    storeJwt,
    dimensions,
    filterType,
    prompt,
    updateDimensions,
    sample,
  ]);

  const onChangeTheme = (theme) => {
    dispatch(setAutoSavedPending());
    setSelectedTheme(theme);
    updateVisualizationTheme(theme);
  };

  const onFilterTypeChange = (e) => {
    dispatch(setAutoSavedPending());
    setFilterType(e.target.value);
    if (e.target.value === QUESTION_FILTER_TYPE.ATTRIBUTE) {
      updateSampleAndDimensions(SurveyThemeNulled, e.target.value);
    } else {
      updateSampleAndDimensions(selectedTheme, e.target.value);
    }
  };

  useEffect(() => {
    if (!sampleUpdated || !sample) return;
    dispatch(getSurveySampleById(sample.Id, storeJwt));
  }, [sampleUpdated, sample, storeJwt, dispatch]);

  useEffect(() => {
    if (!visualizations || visualizations.length <= 0) return;
    setSelectedTheme(themes[visualizations.details.scheme.index]);
  }, [visualizations]);

  useEffect(() => {
    if ((!autoSaved && autoSaving && responseError) || !autoSavingPending) return;
    setTimeout(() => {
      dispatch(autoSavingSurveySample());
    }, AUTO_SAVING_TIME);
  }, [dispatch, autoSaved, autoSaving, autoSavingPending, responseError]);

  useEffect(() => {
    if ((!autoSaving && autoSaved)) return;
    if (responseError) return;
    onSave();
  }, [autoSaved, autoSaving, onSave, responseError]);

  useEffect(() => {
    if (!responseError) return;
    handleEditMode(null, 'sections');
    SampleDimension404Dialog(dispatch);
  }, [responseError, handleEditMode, dispatch]);

  return (
    <Col md="3" className="p-3 survey-sidebar edit-mode">
      <span
        className="d-flex"
        onClick={() => handleEditMode(null, 'sections')}
        onKeyDown={() => handleEditMode(null, 'sections')}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon size="sm" className="mr-3" icon={faChevronLeft} />
        Back to overview
      </span>
      <Col className="info-edit-form">
        <div>
          <h4>Question Text</h4>
          <Form.Control
            as="textarea"
            className="mt-3"
            value={prompt}
            onChange={onQuestionPromtChange}
            style={{ height: '100px' }}
            maxLength={500}
            placeholder="Survey Description"
          />
        </div>
        {sample?.Data.valueType !== QUESTION_VALUE_TYPE.MULTIPLE && answers.length > 0 && (
          <div>
            <h4>Answers</h4>
            {answers.map((answer, index) => {
              return (
                <Form.Control
                  className="mt-3"
                  key={answer.id}
                  id={answer.id}
                  value={answer.value}
                  type="text"
                  onChange={(e) => onAnswerChange(e, index)}
                />
              );
            })}
          </div>
        )}
        {[QUESTION_VALUE_TYPE.MULTIPLE, QUESTION_VALUE_TYPE.GRID].includes(
          sample?.Data.valueType
        ) && labels.length > 0 &&  (
            <div>
              <h4>Labels</h4>
              {labels.map((label, index) => {
                return (
                  <Form.Control
                    className="mt-3"
                    key={label.id}
                    id={label.id}
                    value={label.value}
                    type="text"
                    onChange={(e) => onLabelChange(e, index)}
                  />
                );
              })}
            </div>
          )}
        {sample &&
          ![
            QUESTION_VALUE_TYPE.GRID_MULTIPLE,
            QUESTION_VALUE_TYPE.RANK_ORDER,
            QUESTION_VALUE_TYPE.OPEN_TEXT,
          ].includes(sample?.Data.valueType) && (
            <FilterCategory
              filterType={filterType}
              onFilterTypeChange={onFilterTypeChange}
              sample={sample}
            />
          )}
        {sample &&
          filterType !== QUESTION_FILTER_TYPE.ATTRIBUTE &&
          [QUESTION_VALUE_TYPE.SINGLE, QUESTION_VALUE_TYPE.GRID].includes(
            sample?.Data.valueType
          ) && (
            <>
              <h4 className="mb-2">
                Select Color Scheme
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      Gradient: For data in an ordered series (like increasing lengths of time)
                      <br />
                      Discrete: For data with no inherent order or rank (like school subjects)
                      <br />
                      Positive: For ordered data with two ends and a middle (like Likert scale
                      <br />
                      questions)
                    </Tooltip>
                  }
                  delay={100}
                >
                  <FontAwesomeIcon className="cursor-pointer ml-2" size="md" icon={faInfoCircle} />
                </OverlayTrigger>
              </h4>
              {themes.map((theme) => (
                <div key={`theme-${theme.index}`}>
                  <div
                    className={`colors-rounded ${theme.index === selectedTheme?.index ? 'selected' : ''
                      }`}
                    onClick={() => onChangeTheme(theme)}
                    role="button"
                    style={{ display: 'flex' }}
                  >
                    {theme.positive_negative.map((color) => (
                      <div
                        role="button"
                        key={`color-${color}`}
                        style={{ backgroundColor: color, flex: 1, height: 20 }}
                      />
                    ))}
                  </div>
                  <span>{theme.label}</span>
                </div>
              ))}
            </>
          )}
      </Col>
      <Button variant="primary" onClick={onSave} className="btn btn-custom dark mt-3">
        Save
      </Button>
    </Col>
  );
};

SurveySTypeQuestionEditForm.propTypes = {
  handleEditMode: PropTypes.func.isRequired,
};

export default SurveySTypeQuestionEditForm;
