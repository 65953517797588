import moment from 'moment';

export const cloneArrayOfObjects = (arr = []) => {
  const newArr = JSON.parse(JSON.stringify(arr));
  return newArr;
};

export const isIdOnArray = (arr = [], ObjectName = '') =>
  arr.some((obj) => obj.ObjectName === ObjectName);

export const deleteObjectByKey = (arr = [], key = '') => {
  const newArr = JSON.parse(JSON.stringify(arr));
  const index = newArr.findIndex((obj) => obj.Key === key);
  newArr.splice(index, 1);
  return newArr;
};

export const checkFilesAmount = (arr = []) => {
  const keysArr = arr.map((item) => item.Key);
  const uploadedArr = arr.map((item) => item.isUploaded);
  const uniqKeys = [...new Set(keysArr)];
  const uniqUploaded = uploadedArr.every((val, i, arrr) => val === arrr[0]);
  return uniqKeys.length === 3 && uniqUploaded;
};

export const findIndexOf = (arr = [], id) => {
  let index;
  arr.forEach((element) => {
    if (element.Id === id) index = arr.indexOf(element);
    if (element.Drafts) index = findIndexOf(element.Drafts, element.Id);
  });
  return index;
};

export const epochToDateHuman = (epoch) => {
  let epochTime = epoch;
  if (epoch < 10000000000) {
    epochTime *= 1000; // convert to milliseconds (Epoch is usually expressed in seconds, but Javascript uses milliseconds)
  }
  epochTime += new Date().getTimezoneOffset() * -1; // for timeZone
  const date = new Date(epochTime);
  return moment(date).format('YYYY MMMM Do, h:mm:ss a');
};

export const dateFormat = (date) => {
  const m = moment(date);
  return m.isValid() ? m.format('MM/DD/YYYY') : 'N/A';
};

export const getNumericString = (str) => {
  return str.replace(/\D/g, ''); // Removes all non-numeric characters
};
