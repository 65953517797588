export const getSurveyDescription = (survey, descriptionList) => {
  const formattedYearMonth = `${survey.year}_${survey.month.slice(0, 2)}`;

  const metaDescription = descriptionList.find(
    (metaDesc) =>
      metaDesc.Data.type === survey.type &&
      metaDesc.Data.subtype === survey.subType &&
      metaDesc.Data.year === formattedYearMonth // Exact match to "YYYY_MM"
  );

  return metaDescription;
};
