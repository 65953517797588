/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Button,
  ButtonGroup,
  Container,
  Col,
  FormControl,
  FormCheck,
  FormLabel,
  InputGroup,
  Modal,
  Table,
  Row,
  Spinner,
  Alert,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import jwtDecode from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPeopleArrows,
  faRedo,
  faUserPlus,
  faTrash,
  faTimesCircle,
  faSearch,
  faSortAlphaDown,
  faSortAlphaUp,
  faPencilAlt,
  faUsersCog,
} from '@fortawesome/free-solid-svg-icons';

import { getUsersList, deleteUser, exportUsers } from '../../../../actions/user.action';
import Pagination from '../../../../Components/Pagination';
import { UserForm, UserBatch } from '..';
import types from '../../../../types';
import { ROLES } from '../../../../constants/common';
import { getOrgList, getOrgListByUser } from '../../../../actions/organization.actions';
import {
  assignOrgToUserApi,
  createUserApi,
  removeOrgToUserApi,
  updateUserApi,
  demoteOrgUserApi,
} from '../../../../api/users';

const UserList = () => {
  const dispatch = useDispatch();
  const [usernameFilter, setUsernameFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [orgFilter, setOrgFilter] = useState('');
  const [firstNameFilter, setFirstNameFilter] = useState('');
  const [lastNameFilter, setLastNameFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortDir, setSortDir] = useState('');
  const [sortCol, setSortCol] = useState('');
  const [show, setShow] = useState(false);
  const [showMultiDelete, setShowMultiDelete] = useState(false);
  const [showDemote, setShowDemote] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [saveError, setSaveError] = useState();
  const {
    loading,
    users: userList,
    totalSize,
    exportFile,
    isProcessingExport,
    canGetList,
  } = useSelector((state) => state.users);
  const { organizations: orgList, loading: orgLoading } = useSelector(
    (state) => state.organizations
  );
  const storeJwt = useSelector((state) => state.session.jwt);
  const currentUser = jwtDecode(storeJwt);

  const handleClose = () => {
    setShow(false);
    setShowMultiDelete(false);
    setShowDemote(false);
    setEditItem(null);
    setDeleteItem({});
  };

  const handleCreateUser = () => {
    setEditItem({
      email: '',
      firstName: '',
      lastName: '',
      role: '',
      institution: '',
      organizations: [],
    })
    setShow(true);
    dispatch({ type: types.reducerTypes.USERS_SAVE_CLEAR });
  };

  const retrieveUsers = () => {
    const filter = {
      page: currentPage,
      size: pageSize,
    };
    if (sortDir !== '' && sortCol !== '') {
      filter.dir = sortDir;
      filter.sort = sortCol;
    } else {
      // Order by last login by default
      filter.dir = 'desc';
      filter.sort = 'lastLogin';
    }
    if (usernameFilter !== '') {
      filter.uname = usernameFilter;
    }
    if (firstNameFilter !== '') {
      filter.first = firstNameFilter;
    }
    if (lastNameFilter !== '') {
      filter.last = lastNameFilter;
    }
    if (roleFilter !== '') {
      filter.role = roleFilter;
    }
    if (orgFilter !== '') {
      filter.organizationId = orgFilter;
    }
    dispatch(getUsersList(filter, storeJwt));
  };

  const handleCheckAll = (e) => {
    setCheckAll(e.target.checked);
  };

  const handleCheckUser = (user) => {
    const userIndex = selectedUsers.findIndex((su) => su === user.id);
    if (userIndex === -1) {
      setSelectedUsers([...selectedUsers, user.id]);
    } else {
      setSelectedUsers(selectedUsers.filter((id) => user.id !== id));
    }
  };

  const handleSearch = () => {
    setEditItem(null);
    setSaveError(null);
    setShow(false);
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      retrieveUsers();
    }
  };

  useEffect(() => {
    if (currentUser.role === 'ORGANIZATION_ADMIN') {
      dispatch(getOrgListByUser(currentUser.userId, storeJwt));
    } else {
      dispatch(getOrgList(null, storeJwt));
    }
  }, []);

  useEffect(() => {
    retrieveUsers();
  }, [currentPage, pageSize, sortDir, sortCol]);

  useEffect(() => {
    if (exportFile && exportFile !== '' && !isProcessingExport) {
      const link = document.createElement('a');
      link.download = true;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.href = exportFile;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [exportFile, isProcessingExport]);

  useEffect(() => {
    if (canGetList) {
      retrieveUsers();
    }
  }, [canGetList]);

  const handleClearFilters = () => {
    setUsernameFilter('');
    setFirstNameFilter('');
    setRoleFilter('');
    setOrgFilter('');
    setLastNameFilter('');
    setSortDir('');
    setSortCol('');
    const filter = {
      page: currentPage,
      size: pageSize,
      dir: 'desc',
      sort: 'lastLogin',
    };
    dispatch(getUsersList(filter, storeJwt));
  };

  const handleUsernameFilterChange = (e) => {
    setUsernameFilter(e.target.value);
  };

  const handleFirstNameFilterChange = (e) => {
    setFirstNameFilter(e.target.value);
  };

  const handleLastNameFilterChange = (e) => {
    setLastNameFilter(e.target.value);
  };

  const handleRoleFilterChange = (e) => {
    setRoleFilter(e.target.value);
  };

  const handleOrgFilterChange = (e) => {
    setOrgFilter(e.target.value);
  };

  const handleSortChange = (newSortCol) => {
    if (sortCol === newSortCol) {
      setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
    } else {
      setSortCol(newSortCol);
      setSortDir('asc');
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
  };

  const handleChangePage = (newPage) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPage(newPage);
  };

  const handleUpdateUser = async (editUser) => {
    const currentOrgIds = editItem.organizations.map((org) => org.id);
    const newOrgs = editUser.organization.filter((oId) => !currentOrgIds.includes(oId));
    const orgsToRemove = currentOrgIds.filter((oId) => !editUser.organization.includes(oId));
    updateUserApi(
      {
        role: editUser.role,
        firstName: editUser.firstName,
        lastName: editUser.lastName,
        institution: editUser.institution,
      },
      storeJwt,
      editItem.id
    );
    if (newOrgs.length) {
      await Promise.all(
        newOrgs.map((orgId) =>
          assignOrgToUserApi(editItem.id, orgId, editUser.role === 'ORGANIZATION_ADMIN', storeJwt)
        )
      );
    }
    if (orgsToRemove.length) {
      await Promise.all(
        orgsToRemove.map((orgId) => removeOrgToUserApi(editItem.id, orgId, storeJwt))
      );
    }
    retrieveUsers();
  };

  const handleSave = async (newUser) => {
    if (editItem.id) {
      handleUpdateUser(newUser);
    } else {
      try {
        const userCreated = await createUserApi(newUser, storeJwt);
        await Promise.all(
          newUser.organization.map((org) =>
            assignOrgToUserApi(
              userCreated.data.id,
              org,
              newUser.role === 'ORGANIZATION_ADMIN',
              storeJwt
            )
          )
        );
        setSaveError(null);
        setShow(false);
      } catch (e) {
        setSaveError(e?.response?.data?.message || 'Unexpected Error!');
      }
    }
    handleSearch();
  };

  const handleDeleteUser = (ids) => {
    dispatch(deleteUser(ids, storeJwt));
    setShowMultiDelete(false);
  };

  const handleDemoteUser = async () => {
    await Promise.all(selectedUsers.map((id) => demoteOrgUserApi(id, storeJwt)));
    setShowDemote(false);
    retrieveUsers();
  };

  const handleDemoteSingleUser = async (id) => {
    await demoteOrgUserApi(id, storeJwt);
    retrieveUsers();
  };

  const availableRoles = useMemo(() => {
    if (currentUser.role === 'SUPER_ADMIN') {
      return ROLES;
    }
    if (currentUser.role === 'ORGANIZATION_ADMIN') {
      return ['ORGANIZATION_ADMIN', 'NATIONAL_USER'];
    }
    return ['ADMIN', 'NATIONAL_USER'];
  }, [currentUser]);

  const handleDelete = () => {
    if (currentUser.role === 'ORGANIZATION_ADMIN') {
      handleDemoteSingleUser(deleteItem.id);
    } else {
      handleDeleteUser([deleteItem.id]);
    }
    setDeleteItem({});
  };

  const handleDeleteMultiple = () => {
    handleDeleteUser(selectedUsers);
  };

  const handleExportUsers = () => {
    dispatch(exportUsers(storeJwt));
  };

  const filterOrgs = (org) =>
    currentUser.role === 'SUPER_ADMIN' ||
    currentUser.role === 'ADMIN' ||
    orgList.findIndex((o) => o.id === org.id) >= 0;

  const usersSelectedHaveMoreOrgs = () => {
    let result = false;
    const users = userList.filter((user) => selectedUsers.includes(user.id));
    users.forEach((user) => {
      if (user.organizations) {
        const filteredUserOrgs = user.organizations.filter(filterOrgs);
        if (filteredUserOrgs.length > 1) {
          result = true;
        }
      }
    });
    return result;
  };

  return (
    <Container fluid className="p-5">
      <Row className="mb-2">
        <ButtonGroup>
          <button
            title="Create User"
            className="btn btn-custom dark"
            type="button"
            onClick={handleCreateUser}
          >
            <FontAwesomeIcon icon={faUserPlus} />
          </button>
          <UserBatch />
          <button
            title={
              currentUser.role === 'ORGANIZATION_ADMIN'
                ? 'Remove all selected from all organizations'
                : 'Delete Selected Users'
            }
            className="btn btn-custom dark"
            type="button"
            disabled={selectedUsers.length === 0}
            onClick={() => setShowMultiDelete(true)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            title="Clear Filters"
            className="btn btn-custom dark"
            type="button"
            onClick={handleClearFilters}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
          <button
            title="Export Users"
            className="btn btn-custom dark"
            disabled={isProcessingExport}
            type="button"
            onClick={handleExportUsers}
          >
            <FontAwesomeIcon icon={faUsersCog} />
          </button>
          <button
            title="Refresh Users List"
            className="btn btn-custom dark"
            type="button"
            onClick={retrieveUsers}
          >
            <FontAwesomeIcon icon={faRedo} />
          </button>
          {currentUser.role === 'SUPER_ADMIN' && (
            <button
              title="Remove from all organizations"
              className="btn btn-custom dark"
              variant="outline-secondary"
              type="button"
              disabled={selectedUsers.length === 0}
              onClick={() => setShowDemote(true)}
            >
              <FontAwesomeIcon icon={faPeopleArrows} />
            </button>
          )}
        </ButtonGroup>
      </Row>
      <Row>
        <Table responsive="xl" striped bordered hover className="w-100">
          <thead className="custom-thead">
            <tr>
              <th className="text-center align-baseline">
                <FormCheck type="checkbox" onChange={handleCheckAll} checked={checkAll} />
              </th>
              <th>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="username"
                    value={usernameFilter}
                    onChange={handleUsernameFilterChange}
                  />
                  <InputGroup.Append>
                    <Button variant="light" className="btn btn-custom dark" onClick={handleSearch}>
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup.Append>
                  <InputGroup.Append>
                    <Button
                      variant="light"
                      className="btn btn-custom dark"
                      onClick={() => handleSortChange('username')}
                    >
                      <FontAwesomeIcon
                        icon={sortDir === 'asc' ? faSortAlphaUp : faSortAlphaDown}
                        color={sortCol === 'username' ? 'white' : 'gray'}
                      />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </th>
              <th>
                <InputGroup className="mb-3">
                  <FormControl as="select" value={roleFilter} onChange={handleRoleFilterChange}>
                    <option value="">Role</option>
                    {availableRoles.map((r) => (
                      <option key={r} value={r}>
                        {r}
                      </option>
                    ))}
                  </FormControl>
                  <InputGroup.Append>
                    <Button variant="light" className="btn btn-custom dark" onClick={handleSearch}>
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup.Append>
                  <InputGroup.Append>
                    <Button
                      variant="light"
                      className="btn btn-custom dark"
                      onClick={() => handleSortChange('role')}
                    >
                      <FontAwesomeIcon
                        icon={sortDir === 'asc' ? faSortAlphaUp : faSortAlphaDown}
                        color={sortCol === 'role' ? 'white' : 'gray'}
                      />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </th>
              <th>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="First"
                    aria-label="First"
                    aria-describedby="first"
                    value={firstNameFilter}
                    onChange={handleFirstNameFilterChange}
                  />
                  <InputGroup.Append>
                    <Button variant="light" className="btn btn-custom dark" onClick={handleSearch}>
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup.Append>
                  <InputGroup.Append>
                    <Button
                      variant="light"
                      className="btn btn-custom dark"
                      onClick={() => handleSortChange('firstName')}
                    >
                      <FontAwesomeIcon
                        icon={sortDir === 'asc' ? faSortAlphaUp : faSortAlphaDown}
                        color={sortCol === 'firstName' ? 'white' : 'gray'}
                      />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </th>
              <th>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Last"
                    aria-label="Last"
                    aria-describedby="last"
                    value={lastNameFilter}
                    onChange={handleLastNameFilterChange}
                  />
                  <InputGroup.Append>
                    <Button variant="light" className="btn btn-custom dark" onClick={handleSearch}>
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup.Append>
                  <InputGroup.Append>
                    <Button
                      variant="light"
                      className="btn btn-custom dark"
                      onClick={() => handleSortChange('lastName')}
                    >
                      <FontAwesomeIcon
                        icon={sortDir === 'asc' ? faSortAlphaUp : faSortAlphaDown}
                        color={sortCol === 'lastName' ? 'white' : 'gray'}
                      />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </th>
              <th className="text-center align-baseline">
                <FormLabel>Institution</FormLabel>
              </th>
              <th>
                <InputGroup className="mb-3">
                  <FormControl as="select" value={orgFilter} onChange={handleOrgFilterChange}>
                    <option value="">Organizations</option>
                    {orgList.map((r) => (
                      <option key={r.id} value={r.id}>
                        {r.name}
                      </option>
                    ))}
                  </FormControl>
                  <InputGroup.Append>
                    <Button variant="light" className="btn btn-custom dark" onClick={handleSearch}>
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </th>
              <th className="text-center align-baseline">
                <FormLabel className="mr-1">Last Active</FormLabel>
                <Button
                  variant="light"
                  className="btn btn-custom dark"
                  onClick={() => handleSortChange('lastLogin')}
                >
                  <FontAwesomeIcon
                    icon={sortDir === 'asc' ? faSortAlphaUp : faSortAlphaDown}
                    color={sortCol === 'lastName' ? 'white' : 'gray'}
                  />
                </Button>
              </th>
              <th className="text-center align-baseline">
                <FormLabel className="mr-1">Status</FormLabel>
                <Button
                  variant="light"
                  className="btn btn-custom dark"
                  onClick={() => handleSortChange('status')}
                >
                  <FontAwesomeIcon
                    icon={sortDir === 'asc' ? faSortAlphaUp : faSortAlphaDown}
                    color={sortCol === 'lastName' ? 'white' : 'gray'}
                  />
                </Button>
              </th>
              <th className="text-center align-baseline">
                <FormLabel>Options</FormLabel>
              </th>
            </tr>
          </thead>
          {loading && orgLoading && (
            <tbody>
              <tr className="text-center p-5 vh-100">
                <td>
                  <Spinner animation="border" />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && !orgLoading && (
            <tbody>
              {userList.map((user) => (
                <tr key={user.id}>
                  <td>
                    <FormCheck
                      disabled={currentUser.role !== 'SUPER_ADMIN' && user.role === 'SUPER_ADMIN'}
                      type="checkbox"
                      checked={checkAll || selectedUsers.indexOf(user.id) !== -1}
                      onChange={() => handleCheckUser(user)}
                    />
                  </td>
                  <td>{user.username}</td>
                  <td>
                    <span>{user.role}</span>
                  </td>
                  <td>
                    <span>{user.firstName}</span>
                  </td>
                  <td>
                    <span>{user.lastName}</span>
                  </td>
                  <td>
                    <span>{user.institution}</span>
                  </td>
                  <td>
                    <OverlayTrigger
                      trigger={['hover', 'hover']}
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-related-question">
                          {user.organizations
                            ?.filter(filterOrgs)
                            ?.map((o) => o.name)
                            .join(', ')}
                        </Tooltip>
                      }
                    >
                      <div className="org-admin-column">
                        {user.organizations
                          ?.filter(filterOrgs)
                          ?.map((o) => o.name)
                          .join(', ')}
                      </div>
                    </OverlayTrigger>
                  </td>
                  <td>
                    {user.lastLogin && user.lastLogin !== null
                      ? moment(user.lastLogin).format('YYYY MMM DD')
                      : '-'}
                  </td>
                  <td>{user.status && user.status !== null ? user.status : '-'}</td>
                  <td>
                    <ButtonGroup>
                      <Button
                        title="Edit User"
                        disabled={currentUser.role !== 'SUPER_ADMIN' && user.role === 'SUPER_ADMIN'}
                        variant="outline-secondary"
                        className="btn btn-custom dark"
                        onClick={() => {
                          setEditItem(user);
                          setShow(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </Button>
                      <Button
                        title="Delete User"
                        disabled={
                          (currentUser.role !== 'SUPER_ADMIN' && user.role === 'SUPER_ADMIN') ||
                          currentUser.userId === user.id
                        }
                        className="btn btn-custom dark"
                        variant="outline-secondary"
                        onClick={() => setDeleteItem(user)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                      {currentUser.role === 'SUPER_ADMIN' && (
                        <Button
                          title="Remove from all organizations"
                          className="btn btn-custom dark"
                          variant="outline-secondary"
                          onClick={() => {
                            setSelectedUsers([user.id]);
                            setShowDemote(true);
                          }}
                          disabled={user.organizations?.filter(filterOrgs).length === 0}
                        >
                          <FontAwesomeIcon icon={faPeopleArrows} />
                        </Button>
                      )}
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        <Modal show={deleteItem.id !== undefined} onHide={handleClose}>
          <Modal.Dialog>
            <Modal.Header closeButton>
              <Modal.Title>Delete User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {currentUser.role === 'ORGANIZATION_ADMIN' && (
                <p>
                  The user {deleteItem.firstName} {deleteItem.lastName} will be removed from your
                  organization(s). Do you want to continue?
                </p>
              )}
              {currentUser.role !== 'ORGANIZATION_ADMIN' && (
                <p>
                  All information about the user {deleteItem.firstName} {deleteItem.lastName} will
                  be lost. Do you want to continue?
                </p>
              )}
              {currentUser.role === 'ORGANIZATION_ADMIN' &&
                deleteItem.organizations?.filter(filterOrgs).length > 1 && (
                  <Alert variant="warning">
                    <p>
                      NOTE: The user have more organizations assigned, this action will remove them
                      from all.
                    </p>
                  </Alert>
                )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn btn-custom white mr-3"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="outline-danger" onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </Modal>
        <Modal show={showMultiDelete} onHide={handleClose}>
          <Modal.Dialog>
            <Modal.Header closeButton>
              <Modal.Title>Delete User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Your selection includes multiple users. Are you sure you want to delete all selected
                users?
              </p>
              {usersSelectedHaveMoreOrgs() && currentUser.role === 'ORGANIZATION_ADMIN' && (
                <Alert variant="warning">
                  <p>
                    NOTE: Some of the users selected have more organizations assigned, this action
                    will remove them from all.
                  </p>
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                className="btn btn-custom white mr-3"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="outline-danger" onClick={handleDeleteMultiple}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </Modal>
        <Modal show={showDemote} onHide={handleClose}>
          <Modal.Dialog>
            <Modal.Header closeButton>
              <Modal.Title>Remove User(s) from Organization(s)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to remove selected user(s) from the organization(s)?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-secondary"
                className="btn btn-custom white mr-3"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="outline-danger" onClick={handleDemoteUser}>
                Remove user(s) from organization(s)
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </Modal>
      </Row>
      <Row>
        <Col xs={1}>
          <FormControl as="select" onChange={handlePageSizeChange}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </FormControl>
        </Col>
        <Col>
          <Pagination
            currentPage={currentPage}
            onPageChange={handleChangePage}
            pageSize={pageSize}
            totalItems={totalSize}
          />
        </Col>
      </Row>
      <UserForm
        show={show}
        handleClose={handleClose}
        handleSave={handleSave}
        saveError={saveError}
        orgList={orgList}
        editItem={editItem}
      />
    </Container>
  );
};

export default UserList;
