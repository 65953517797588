/* eslint-disable react-hooks/exhaustive-deps */
import { faFileAlt, faInfoCircle, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Collapse, Container, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSectionNameByCodeAndSurvey } from '../../actions/helpers/section.helper';
import { getSections } from '../../actions/section.action';
import {
  createSurveyMetadataDescription,
  getSurveyMetadataDescription,
  getSurveyQuestionsBySurveyIdAndSection,
  updateSurveyMetadata,
  updateSurveyMetadataDescription,
  clearSurveyDetailsCache,
} from '../../actions/survey.action';
import {
  getSurveyLocationFilter,
  getSurveyVisualizations,
} from '../../actions/survey.details.action';

import { getSurveysSummary } from '../../actions/survey.summary.action';
import types from '../../types';
import SurveyInformationEditForm from './components/SurveyInformationEditForm';
import SurveyInformation from './components/SurveyInformation';
import SurveySectionEditForm from './components/SurveySectionEditForm';
import SurveySections from './components/SurveySections';
import SurveySTypeQuestionEditForm from './components/SurveySTypeQuestionEditForm/intex';
import handleSurveySectionSave from './utils/handleSurveySectionSave';
import './styles.css';

import { getNumericString } from '../../helpers/helpers';
import { getSurveyDescription } from './utils/surveyDescription';

const SurveyEditWizard = () => {
  const storeJwt = useSelector((state) => state.session.jwt);
  const dispatch = useDispatch();
  const { surveys } = useSelector((state) => state.surveySummary);
  const { allSections, sectionUpdated } = useSelector((state) => state.sections);

  const { surveyQuestions, isLoadingSurveyQuestions, surveyMetadataDescription } = useSelector(
    (state) => state.surveys
  );
  const [sectionSelected, setSectionSelected] = useState('');
  const [selectedQuestionId, setSelectedQuestionId] = useState(-1);
  const [currentSurvey, setCurrentSurvey] = useState();
  const [selectedSurveySectionData, setSelectedSurveySectionData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  const { id: surveyId } = useParams();

  const handleEditMode = (questionId, type, editing) => {
    setSectionSelected(type);
    setSelectedQuestionId(questionId);
    if (editing) {
      setIsEdit(true);
    } else {
      setIsEdit(!questionId ? !isEdit : true);
    }
  };

  const handleSurveyInfoSave = (surveyInfo) => {
    const { title, description, dateAdministrated } = surveyInfo;
    dispatch(clearSurveyDetailsCache(storeJwt));
    dispatch(
      updateSurveyMetadata(
        currentSurvey.id,
        {
          title,
        },
        storeJwt
      )
    );

    const metaDescription = getSurveyDescription(currentSurvey, surveyMetadataDescription);

    if (metaDescription) {
      dispatch(
        updateSurveyMetadataDescription(
          metaDescription.Id,
          {
            ...metaDescription.Data,
            link: metaDescription?.Data?.link ?? 'https://www.rand.org/',
            administered: dateAdministrated,
            description,
          },
          storeJwt
        )
      );
      dispatch({
        type: types.reducerTypes.SURVEY_UPDATE_METADATA_DESCRIPTION,
        payload: {
          ...metaDescription,
          Data: {
            ...metaDescription.Data,
            link: metaDescription?.Data?.link ?? 'https://www.rand.org/',
            administered: dateAdministrated,
            description,
          },
        },
      });
    } else {
      const newMetadataDescription = {
        subtype: currentSurvey.subType,
        type: currentSurvey.type,
        year: `${currentSurvey.year}_${getNumericString(currentSurvey.month)}`,
        link: 'https://www.rand.org/',
        administered: dateAdministrated,
        description,
      };
      dispatch(createSurveyMetadataDescription(newMetadataDescription, storeJwt));
      dispatch({
        type: types.reducerTypes.SURVEY_UPDATE_METADATA_DESCRIPTION,
        payload: {
          Id: -1,
          Data: newMetadataDescription,
        },
      });
    }
    setCurrentSurvey({
      ...currentSurvey,
      title,
      surveySummaryDetailsDto: {
        ...currentSurvey.surveySummaryDetailsDto,
        description,
        dateAdministrated,
      },
    });
  };

  const handleSelectSection = (sectionCode, sectionName) => {
    const sectionData = allSections.find(
      (sec) => sec.Data.code.toLowerCase() === sectionCode.toLowerCase()
    );
    if (sectionData) {
      setSelectedSurveySectionData(sectionData);
    } else {
      setSelectedSurveySectionData({
        Id: -1,
        Data: {
          code: sectionCode,
          name: sectionName,
        },
      });
    }
  };

  function sortSections(a, b) {
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  }

  useEffect(() => {
    if (selectedSurveySectionData) {
      dispatch(
        getSurveyQuestionsBySurveyIdAndSection(
          currentSurvey.id,
          selectedSurveySectionData.Data.code,
          storeJwt
        )
      );
    }
  }, [selectedSurveySectionData]);

  useEffect(() => {
    dispatch(getSurveysSummary(storeJwt));
    dispatch(getSections(storeJwt));
    dispatch(getSurveyMetadataDescription(storeJwt));
  }, [dispatch, storeJwt]);

  useEffect(() => {
    if (surveyId && surveys?.length) {
      dispatch(getSurveyVisualizations(surveyId, storeJwt));
      dispatch(getSurveyLocationFilter(surveyId, storeJwt));
      const survey = surveys.find((s) => Number(s.id) === Number(surveyId));
      if (survey) {
        setCurrentSurvey(survey);
        setIsLoading(false);
      }
    }
  }, [surveyId, surveys]);

  useEffect(() => {
    if (!sectionUpdated) return;
    dispatch(getSections(storeJwt));
  }, [sectionUpdated]);
  return (
    <>
      {isLoading && (
        <Container fluid>
          <Row>
            <Col xs="12" className="d-flex justify-content-center mt-5">
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      )}
      {!isLoading && (
        <Container fluid className="survey-wizard-container">
          <Row>
            {!isEdit && (
              <Col md="3" className="pt-5 survey-sidebar p-0 pr-5">
                <div className="pt-3 pb-4 pl-5">
                  <p>Survey</p>
                  <h2>{currentSurvey?.title}</h2>
                </div>
                <Alert
                  variant="light"
                  className={`alert-container alert-border ${
                    sectionSelected === 'info' ? 'section-selected' : ''
                  }`}
                  onClick={() => setSectionSelected('info')}
                >
                  <FontAwesomeIcon size="lg" className="mr-3" icon={faInfoCircle} />
                  Survey information
                </Alert>
                <Alert
                  variant="light"
                  className={`alert-container alert-border ${
                    ['sections', 's-type-question'].includes(sectionSelected)
                      ? 'section-selected'
                      : ''
                  }`}
                  onClick={() => setSectionSelected('sections')}
                >
                  <FontAwesomeIcon size="lg" className="mr-3" icon={faFileAlt} />
                  Sections
                </Alert>
                {['sections'].includes(sectionSelected) && (
                  <Collapse in={['sections'].includes(sectionSelected)}>
                    <Container className="pl-3 sidebar-section-container">
                      {currentSurvey.surveySummaryDetailsDto?.sectionCodes
                        .split(',')
                        .sort(sortSections)
                        .map((s) => {
                          const sectionName = getSectionNameByCodeAndSurvey(
                            s,
                            currentSurvey,
                            allSections
                          );
                          const selectedSectionCode = s.trim().toLowerCase();
                          const alertVariant =
                            selectedSurveySectionData?.Data?.code !== selectedSectionCode
                              ? 'alert-border'
                              : 'alert-border-selected';
                          return (
                            <Alert
                              key={s.trim()}
                              variant="light"
                              className={`alert-container ${alertVariant}`}
                              onClick={() => handleSelectSection(s.trim(), sectionName.trim())}
                            >
                              {sectionName.trim()}
                            </Alert>
                          );
                        })}
                    </Container>
                  </Collapse>
                )}
                <Alert
                  variant="light"
                  className={`alert-container alert-border ${
                    sectionSelected === 'filters' ? 'section-selected' : ''
                  }`}
                  onClick={() => setSectionSelected('filters')}
                >
                  <FontAwesomeIcon size="lg" className="mr-3" icon={faSlidersH} />
                  Other Filters
                </Alert>
              </Col>
            )}
            {isEdit && sectionSelected === 'info' && (
              <SurveyInformationEditForm
                currentSurvey={currentSurvey}
                handleEditMode={(questionId) => handleEditMode(questionId, sectionSelected)}
                handleSave={handleSurveyInfoSave}
              />
            )}
            {isEdit && sectionSelected === 'sections' && (
              <SurveySectionEditForm
                currentSection={() =>
                  getSectionNameByCodeAndSurvey(
                    selectedSurveySectionData.Data.code,
                    currentSurvey,
                    allSections
                  )
                }
                handleEditMode={() => handleEditMode(null, 'sections')}
                handleSave={(sectionInfo) =>
                  handleSurveySectionSave(
                    sectionInfo,
                    selectedSurveySectionData,
                    currentSurvey,
                    storeJwt
                  )(setSelectedSurveySectionData, dispatch)
                }
              />
            )}
            {isEdit && sectionSelected === 's-type-question' && (
              <SurveySTypeQuestionEditForm
                handleEditMode={handleEditMode}
                handleSave={(sectionInfo) =>
                  handleSurveySectionSave(
                    sectionInfo,
                    selectedSurveySectionData,
                    currentSurvey,
                    storeJwt
                  )(setSelectedSurveySectionData, dispatch)
                }
              />
            )}
            <Col
              md="9"
              className={clsx([
                'survey-sections-container pt-5',
                { 'empty-section': !sectionSelected },
              ])}
            >
              {!sectionSelected && (
                <>
                  <img src="/empty-section.svg" alt="select a section" />
                  <h2>Choose a Section to edit</h2>
                </>
              )}
              {sectionSelected === 'info' && (
                <SurveyInformation
                  currentSurvey={currentSurvey}
                  onEditModeClick={(questionId) => handleEditMode(questionId, 'info')}
                />
              )}
              {!isLoadingSurveyQuestions &&
                selectedSurveySectionData &&
                ['sections', 's-type-question'].includes(sectionSelected) && (
                  <SurveySections
                    sectionName={getSectionNameByCodeAndSurvey(
                      selectedSurveySectionData.Data.code,
                      currentSurvey,
                      allSections
                    )}
                    surveyQuestions={surveyQuestions}
                    onEditModeClick={handleEditMode}
                    onQuestionClick={() => setSectionSelected('s-type-question')}
                    isEditSection={isEdit && sectionSelected === 'sections'}
                    selectedQuestionId={selectedQuestionId}
                  />
                )}
              {isLoadingSurveyQuestions && (
                <Container fluid>
                  <Row>
                    <Col className="d-flex min-vh-100 justify-content-center align-items-center">
                      <Spinner animation="border" />
                    </Col>
                  </Row>
                </Container>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default SurveyEditWizard;
