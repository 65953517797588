/**
 *
 *  wizard: Used to render different component types.reducerTypes.
    wizardRule: Used to validate the business logic.
    providerLabel: Used on the vendorSelect component, to show providers on the dropdown.
 *
 */
const providers = [
  {
    wizard: 'RAND', // How the UI will be displayed
    wizardRule: 'RAND', // Key (usually provider) that will set the rules of the bussiness.
    providerLabel: 'RAND CORPORATION',
    steps: {
      step0: {
        ui: [
          {
            component: 'customDropdown',
            label: 'Select an organization',
            emptyLabel: 'No organization selected',
            type: 'default',
            required: false,
            asyncAction: 'getAllOrganizations',
          },
          {
            component: 'upload',
            name: 'fileName',
            label: 'Variable listing file',
            required: true,
            type: 'csv',
            vendorDataType: 'fileName',
          },
          {
            component: 'upload',
            name: 'sasFileName',
            label: 'SAS file with value labels',
            required: true,
            type: 'txt',
            vendorDataType: 'sasFileName',
          },
          {
            component: 'upload',
            name: 'dataFile',
            label: 'Data file',
            required: true,
            type: 'csv',
            vendorDataType: 'dataFile',
          },
          {
            component: 'customDropdown',
            label: 'Associated survey',
            emptyLabel: 'No surveys selected',
            type: 'default',
            required: false,
            asyncAction: 'getAllSurveys',
          },
        ],
        formAction: {
          buttonLabel: 'Import to Bento',
          buttonAction: 'importToBento',
          validation: 'rand_s0_importToBento',
        },
      },
    },
    informative: {
      title: 'Add new survey data',
      imageSrc: '/upload-art.svg',
      imageAlt: 'Add New Data Ilustration',
      description: 'Upload the data files for your survey and then import them to Bento',
    },
  },
  {
    wizard: 'QUALTRICS', // How the UI will be displayed
    wizardRule: 'QUALTRICS', // Key (usually provider) that will set the rules of the bussiness.
    providerLabel: 'QUALTRICS',
    steps: {
      step0: {
        ui: [
          {
            component: 'customDropdown',
            label: 'Select an organization',
            emptyLabel: 'No organization selected',
            type: 'default',
            required: false,
            asyncAction: 'getAllOrganizations',
          },
          {
            component: 'upload',
            name: 'ddlResponseData',
            label: 'Response data CSV',
            required: true,
            type: 'csv',
            vendorDataType: 'ddlResponseData',
          },
          {
            component: 'upload',
            name: 'ddlSurvyInstrument',
            label: 'Survey instrument QSF',
            required: true,
            type: 'qsf',
            vendorDataType: 'ddlSurvyInstrument',
          },
          {
            component: 'customDropdown',
            label: 'Associated survey',
            emptyLabel: 'No surveys selected',
            type: 'default',
            required: false,
            asyncAction: 'getAllSurveys',
          },
        ],
        formAction: {
          buttonLabel: 'Import to Bento',
          buttonAction: 'qualtricsImportToBento',
          validation: 'qualtrics_importToBento',
        },
      },
    },
    informative: {
      title: 'Add new survey data',
      imageSrc: '/upload-art.svg',
      imageAlt: 'Add New Data Ilustration',
      description: 'Upload the data files for your survey and then import them to Bento',
      actionLabel: 'Click here for Qualtrics download instructions',
      action: (openModal, dispatch, content) => {
        openModal({
          className: 'provider-modal',
          button1Text: 'Okay',
          button1Action: 'closeModal',
          body: content,
        })(dispatch);
      },
    },
  },
  {
    wizard: 'replaceDataSet',
    wizardRule: 'RAND',
    providerLabel: null,
    steps: {
      step0: {
        ui: [
          {
            component: 'upload',
            name: 'fileName',
            label: 'Variable listing file',
            required: true,
            type: 'csv',
            vendorDataType: 'fileName',
          },
          {
            component: 'upload',
            name: 'sasFileName',
            label: 'SAS file with value labels',
            required: true,
            type: 'txt',
            vendorDataType: 'sasFileName',
          },
          {
            component: 'upload',
            name: 'dataFile',
            label: 'Data file',
            required: true,
            type: 'csv',
            vendorDataType: 'dataFile',
          },
          {
            component: 'customDropdown',
            label: 'Associated survey',
            emptyLabel: 'No surveys selected',
            type: 'default',
            required: false,
            asyncAction: 'getAllSurveys',
          },
        ],
        formAction: {
          buttonLabel: 'Import to Bento',
          buttonAction: 'replaceDataSet',
          validation: 'replaceDataSet_s0_importToBento',
        },
      },
    },
    informative: {
      title: 'Replace existing survey data',
      imageSrc: '/art-replace.svg',
      imageAlt: 'Add New Data Ilustration',
      description: 'Upload the data files for your survey and then import them to Bento',
    },
  },
  {
    wizard: 'visualization',
    wizardRule: 'CUSTOM-KITAMBA-DASHBOARD',
    providerLabel: null,
    steps: {
      step0: {
        ui: [
          {
            component: 'input',
            type: 'text',
            label: 'Data Title',
            name: 'fileName',
            required: true,
            maxChar: 60,
          },
          {
            component: 'input',
            type: 'area',
            label: 'Description',
            name: 'description',
            required: false,
            maxChar: 220,
          },
          {
            component: 'formGroup',
            group: [
              {
                component: 'input',
                type: 'text',
                label: 'Year(s)',
                name: 'years',
                required: false,
                maxChar: 30,
              },
              {
                component: 'checkbox',
                label: 'Granularity',
                checkboxes: [
                  {
                    label: 'State',
                    name: 'state',
                    required: false,
                  },
                  {
                    label: 'District',
                    name: 'district',
                    required: false,
                  },
                ],
              },
            ],
          },
        ],
        formAction: {
          buttonLabel: 'Next',
          buttonAction: 'nextStep',
          validation: 'visualization_s0',
        },
      },
      step1: {
        ui: [
          {
            component: 'upload',
            name: 'dataFile',
            label: 'Raw Data',
            required: true,
            type: 'csv',
            vendorDataType: 'dataFile',
          },
          {
            component: 'upload',
            name: 'sasFileName',
            label: 'SAS file with value labels',
            required: false,
            type: 'txt',
            vendorDataType: 'sasFileName',
          },
          {
            component: 'upload',
            name: 'data',
            label: 'Data file',
            required: false,
            type: 'csv',
            vendorDataType: 'data',
          },
        ],
        formAction: {
          buttonLabel: 'Import to Bento',
          buttonAction: 'importSampleDashboardToBento',
          validation: 'visualization_s1_bento',
        },
      },
    },
    informative: {
      title: 'Add new data',
      imageSrc: '/upload-art.svg',
      imageAlt: 'Add New Data Ilustration',
      description: 'Upload your data files and then import them to Bento',
    },
  },
];
export default providers;
