import React from 'react';
import PropTypes from 'prop-types';
import { Form, ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { QUESTION_FILTER_TYPE, QUESTION_VALUE_TYPE } from '../../../../constants/common';
import { openModal } from '../../../../actions/modal.action';
import { useSelector } from 'react-redux';

const FilterCategory = ({ filterType, onFilterTypeChange, sample }) => {
  const dispatch = useDispatch();
  const { surveyLocationFilter } = useSelector((state) => state.surveyDetails);
  const handleAttributeType = (event) => {
    if (surveyLocationFilter && surveyLocationFilter.id !== sample.Id) {
      openModal({
        className: 'modal-alert-width',
        button1Text: 'Okay',
        button1Action: 'closeModal',
        imageSrc: '/icons/triangle.png',
        body:
          'This question will not be shown as a chart, but will only be available as an attribute filter',
      })(dispatch);
      return;
    }
    onFilterTypeChange(event);
  };
  return (
    <>
      <div className="border-gray mt-3" />
      <h4 className="mb-3">Filter Category</h4>
      <ListGroup>
        <ListGroup.Item>
          <Form.Check
            type="radio"
            checked={filterType === QUESTION_FILTER_TYPE.NONE}
            id="filter-none"
            label="None"
            value="NONE"
            onChange={onFilterTypeChange}
          />
        </ListGroup.Item>
        {sample?.Data.valueType !== QUESTION_VALUE_TYPE.MULTIPLE && (
          <ListGroup.Item>
            <Form.Check
              checked={filterType === QUESTION_FILTER_TYPE.LOCATION}
              type="radio"
              id="filter-location"
              label="Location"
              value="LOCATION"
              onChange={handleAttributeType}
            />
          </ListGroup.Item>
        )}
        <ListGroup.Item>
          <Form.Check
            checked={filterType === QUESTION_FILTER_TYPE.ATTRIBUTE}
            type="radio"
            id="filter-attribute"
            label="Attribute"
            value="ATTRIBUTE"
            onChange={onFilterTypeChange}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <Form.Check
            checked={filterType === QUESTION_FILTER_TYPE.QUESTION}
            type="radio"
            id="filter-question"
            label="Question"
            value="QUESTION"
            onChange={onFilterTypeChange}
          />
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};

FilterCategory.propTypes = {
  filterType: PropTypes.string.isRequired,
  onFilterTypeChange: PropTypes.func.isRequired,
  sample: PropTypes.instanceOf(Object).isRequired,
};

export default FilterCategory;
